import * as React from "react";

import Layout from "../components/layout";

import feedbackImage from "../images/notepad.png";
import supportImage from "../images/stamp.png";

import facebook from "../images/facebook.png";
import twitter from "../images/twitter.png";
import youtube from "../images/youtube.png";
import instagram from "../images/instagram.png";

export default () => {
  return (
    <Layout>
      <div className="container my-5">
        <h1 className="text-center text-md-left">Contact Us</h1>
        <hr />
        <div className="row my-5 element josh-js"
             data-josh-anim-name="fadeInLeft"
             data-josh-anim-delay="2s"
             data-josh-duration="1000ms">
          <div className="col-md-3 text-center text-md-left">
            <img className="col-img contact-img" src={feedbackImage} alt="Feedback" />
          </div>
          <div className="col-md-9 text-center text-md-left">
            <h2 className="my-4">Feedback & Suggestions</h2>
            <p>
              If you have any feeback or suggestions for our games or even new
              games, please send an email to{" "}
              <a href="mailto:feedback@wildfire-games.com">
                feedback@wildfire-games.com
              </a>
              .
            </p>
          </div>
        </div>
        <hr className="my-5" />
        <h2 className="my-4 text-center text-md-left element josh-js"
            data-josh-anim-name="fadeIn"
            data-josh-anim-delay="2s"
            data-josh-duration="2000ms">Follow Us!</h2>
        <p className="my-4 mb-5 text-center text-md-left element josh-js"
           data-josh-anim-name="fadeIn"
           data-josh-anim-delay="2s"
           data-josh-duration="2000ms">
          Keep up to date with what we're working on through our social media channels.
        </p>
        <div className="row text-center">
          <div className="col-6 col-lg-3 element josh-js"
               data-josh-anim-name="bounceIn"
               data-josh-anim-delay="2s"
               data-josh-duration="1000ms">
            <a href="https://m.facebook.com/wildfiregamesuk/">
              <img className="col-img contact-img" src={facebook} alt="Facebook" />
            </a>
          </div>
          <div className="col-6 col-lg-3 element josh-js"
               data-josh-anim-name="bounceIn"
               data-josh-anim-delay="2s"
               data-josh-duration="1000ms">
            <a href="https://www.instagram.com/wildfiregamesuk/">
              <img className="col-img contact-img" src={instagram} alt="Instagram" />
            </a>
          </div>
          <div className="col-6 col-lg-3 mt-5 mt-lg-0 element josh-js"
               data-josh-anim-name="bounceIn"
               data-josh-anim-delay="2s"
               data-josh-duration="1000ms">
            <a href="https://twitter.com/wildfiregamesuk">
              <img className="col-img contact-img" src={twitter} alt="Twitter" />
            </a>
          </div>
          <div className="col-6 col-lg-3 mt-5 mt-lg-0 element josh-js"
               data-josh-anim-name="bounceIn"
               data-josh-anim-delay="2s"
               data-josh-duration="1000ms">
            <a href="https://youtube.com/channel/UC9fB0JsvHenxrERcOzCi8SQ">
              <img className="col-img contact-img" src={youtube} alt="YouTube" />
            </a>
          </div>
        </div>
        <hr className="my-5" />
        <div className="row my-5 element josh-js"
             data-josh-anim-name="fadeInRight"
             data-josh-anim-delay="2s"
             data-josh-duration="1000ms">
          <div className="col-md-3 text-center text-md-left">
            <img className="col-img contact-img" src={supportImage} alt="Support" />
          </div>
          <div className="col-md-9 text-center text-md-left">
            <h2 className="my-4">Support Queries</h2>
            <p>
              For any support queries about any of our games, please send an
              email to{" "}
              <a href="mailto:support@wildfire-games.com">
                support@wildfire-games.com
              </a>
              . Alternatively, fill in the form below.
            </p>
          </div>
        </div>
        <iframe
          className="form element josh-js"
          data-josh-anim-name="fadeInUp"
          data-josh-anim-delay="2s"
          data-josh-duration="1000ms"
          src="https://docs.google.com/forms/d/e/1FAIpQLScmgtQMV_7VTCONlhkS5AJe3aCCTneuGIWiOOc2JWTYgHt9eg/viewform?embedded=true"
          height="1000"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
    </Layout>
  );
};
